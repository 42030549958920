<template>
  <div class="row justify-content-center">
    <customer-header />

    <vs-prompt
      color="primary"
      title=""
      accept-text="บันทึก"
      cancel-text="ยกเลิก"
      @accept="updateHome"
      @close="close"
      :is-valid="validName"
      :active.sync="updatePrompt"
    >
      <div class="con-exemple-prompt">
        กรุณากรอกข้อมูลลูกบ้าน

        <v-select
          v-if="prefix_addres_list.length > 0"
          label="title"
          placeholder="ขึ้นต้นด้วย"
          v-model="formModel.prefix_address"
          @input="checkOption"
          :options="prefix_addres_list"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
        />
        <vs-input
          type="number"
          icon="event"
          placeholder="บ้านเลขที่"
          v-model="formModel.house_no"
        />
        <vs-input icon="event" placeholder="ชื่อ" v-model="formModel.name" />
        <vs-input
          type="text"
          icon="event"
          placeholder="อีเมล์"
          v-model="formModel.email"
        />
        <vs-input
          type="number"
          icon="event"
          placeholder="เบอร์โทร"
          v-model="formModel.phone"
        />
        <ul class="centerx">
          <li>
            <vs-checkbox v-model="formModel.active"
              >สถานะการใช้งาน :
              {{ formModel.active ? "เปิด" : "ปิด" }}</vs-checkbox
            >
          </li>
        </ul>
        <vs-alert :active="!validName" color="danger" icon="new_releases">
          กรุณากรอกข้อมูลให้ครบถ้วน
        </vs-alert>
      </div>
    </vs-prompt>

    <vs-prompt
      title=""
      @accept="deleteHouse"
      accept-text="ลบ"
      cancel-text="ยกเลิก"
      @close="close"
      :active.sync="deletePrompt"
    >
      <div class="con-exemple-prompt">ลบข้อมูลนี้?</div>
    </vs-prompt>

    <vs-prompt
      color="primary"
      title=""
      accept-text="บันทึก"
      cancel-text="ยกเลิก"
      @accept="acceptAddHouse"
      @close="close"
      :is-valid="validName"
      :active.sync="activePromptAddHome"
    >
      <div class="con-exemple-prompt">
        กรุณากรอกข้อมูลลูกบ้าน
        <v-select
          v-if="prefix_addres_list.length > 0"
          label="title"
          placeholder="ขึ้นต้นด้วย"
          v-model="formModel.prefix_address"
          @input="checkOption"
          :options="prefix_addres_list"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
        />
        <vs-input
          type="number"
          icon="event"
          placeholder="บ้านเลขที่"
          v-model="formModel.house_no"
        />
        <vs-input icon="event" placeholder="ชื่อ" v-model="formModel.name" />
        <vs-input
          type="text"
          icon="event"
          placeholder="อีเมล์"
          v-model="formModel.email"
        />
        <vs-input
          type="number"
          icon="event"
          placeholder="เบอร์โทร"
          v-model="formModel.phone"
        />
        <ul class="centerx">
          <li>
            <vs-checkbox v-model="formModel.active"
              >สถานะการใช้งาน :
              {{ formModel.active ? "เปิด" : "ปิด" }}</vs-checkbox
            >
          </li>
        </ul>
        <vs-alert :active="!validName" color="danger" icon="new_releases">
          กรุณากรอกข้อมูลให้ครบถ้วน
        </vs-alert>
      </div>
    </vs-prompt>

    <vs-list>
      <vs-list-item class="k-nav-bar" title="จัดการลูกบ้าน" subtitle="">
        <vs-button @click="openAddhome" color="primary" type="filled"
          >เพิ่มลูกบ้าน</vs-button
        >
        <!-- <vs-button @click="genCustomerCode()" color="primary" type="filled">Gen</vs-button> -->
      </vs-list-item>
    </vs-list>
    <vs-card style="margin-top: 10px">
      <div>
        <vs-table stripe :data="items">
          <template slot="thead" style="margin-top: 100px">
            <vs-th>แก้ไข </vs-th>
            <vs-th>ลบ </vs-th>
            <vs-th> บ้านเลขที่ </vs-th>
            <!-- <vs-th> url </vs-th> -->
            <vs-th> ชื่อ </vs-th>
            <vs-th> อีเมล์ </vs-th>
            <vs-th> เบอร์โทร </vs-th>
            <vs-th> สถานะการใช้งาน </vs-th>
            <vs-th>QR Code </vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                <vs-button
                  @click="openUpdatePromt(data[indextr])"
                  color="primary"
                  type="flat"
                  icon="edit"
                ></vs-button>
              </vs-td>
              <vs-td>
                <vs-button
                  @click="openDeletePromt(data[indextr].key)"
                  color="primary"
                  type="flat"
                  icon="delete"
                ></vs-button>
              </vs-td>

              <vs-td :data="data[indextr].house_no">
                {{ data[indextr].house_no_display }}
              </vs-td>
              <vs-td :data="data[indextr].name">
                {{ data[indextr].name }}
              </vs-td>
              <vs-td :data="data[indextr].email">
                {{ data[indextr].email }}
              </vs-td>
              <vs-td :data="data[indextr].phone">
                {{ data[indextr].phone }}
              </vs-td>
              <vs-td :data="data[indextr].active">
                {{
                  data[indextr].active === false ? "ปิดใช้งาน" : "เปิดการใช้งาน"
                }}
              </vs-td>
              <vs-td>
                <a target="_blank" :href="createQR(data[indextr].uid)">สร้าง</a>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <div>
          <br />
          <paginate
            v-model="page"
            :page-count="total"
            :page-range="3"
            :margin-pages="2"
            :click-handler="inputData"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :container-class="'pagination'"
            :page-class="'page-item'"
          >
          </paginate>
        </div>
      </div>
    </vs-card>
  </div>
</template>

<script>
import Vue from "vue";
import VueCryptojs from "vue-cryptojs";
import VueCookies from "vue-cookies";
import { db } from "../store/firebaseDb";
const moment = require("moment");
require("moment/locale/th");
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import CustomerHeader from "@/layouts/components/custom/CustomerHeader.vue";
import vSelect from "vue-select";

console.log("lang", lang);

import Paginate from "vuejs-paginate";
Vue.component("paginate", Paginate);

Vue.use(VueCryptojs);
Vue.use(VueCookies);

Vue.component("customer-header", CustomerHeader);
Vue.component("v-select", vSelect);

moment.locale("th");
Vue.use(require("vue-moment"), {
  moment,
});

console.log(Vue.moment().locale());
export default {
  name: "ManageHome",
  components: {},
  data() {
    return {
      prefix_addres_list: [],
      deletePrompt: false,
      updatePrompt: false,
      allCustomerCode: [],
      roleInfo: {},
      userInfo: null,
      language: "en",
      languages: lang,
      houseInfo: {},
      items: [],
      activePromptAddHome: false,
      val: "",
      selectedId: "",
      formModel: {
        house_no: null,
        name: "",
        email: "",
        phone: "",
        uid: "",
        active: true,
        prefix_address: "",
      },

      highlightedFn: {
        customPredictor(date) {
          const now = moment(new Date()).format("MMMM Do YYYY");
          const d = moment(date).format("MMMM Do YYYY");
          console.log("now=", now);
          console.log("d=", d);
          if (now == d) {
            return true;
          }
        },
      },
      // pagination
      total: 0, // จำนวนหน้า
      // limit: 10,
      page: 1,
      totalItems: 0, // จำนวนข้อมูลทั้งหมด
      isLoad: true,
      show_per_page: 10,
      oldUpdateValue: {},
    };
  },
  computed: {
    validName() {
      console.log("validName=",this.formModel);

      return !this.isEmpty(this.formModel.house_no);
      // return this.formModel.dttm != null && this.formModel.desc.length > 0;
      // return true;
    },
  },
  methods: {
    documentPath() {
      const customerName = $cookies.get("customer");
      const _documentPath = "customer/" + customerName + "/house/";
      return _documentPath;
    },

    createQR(_uid) {
      // var currentUrl = window.location.pathname;
      var currentLocation =
        window.location.protocol + "//" + window.location.host;
      console.log("currentLocation1=" + currentLocation);
      const uidEncode = encodeURIComponent(
        currentLocation + "?_uid=" + encodeURIComponent(_uid)
      );

      //     const uidEncode = encodeURIComponent("http://192.168.1.53:8080?_uid="+encodeURIComponent(_uid));
      return (
        "https://api.qrserver.com/v1/create-qr-code/?color=0000FF&size=3000x300&data=" +
        uidEncode
      );
    },

    checkOption() {
      // console.log("prefix_address ="+this.formModel.prefix_address.value );
    },

    async acceptAddHouse(color) {
      const customerName = $cookies.get("customer");

      if (await this.checkExistAddress()) {
        this.$vs.notify({
          color: "danger",
          title: "",
          text: "บันทึกข้อมูลไม่สำเร็จ บ้านเลขที่ \""+this.getNotifyAddressName()+"\" มีอยู่ในระบบแล้ว",
        });
        return;
      }



      this.formModel.house_no = parseInt(this.formModel.house_no);
      this.formModel.prefix_address =
        this.formModel.prefix_address == null ||
        this.formModel.prefix_address == undefined ||
        this.formModel.prefix_address == ""
          ? ""
          : this.formModel.prefix_address.value;

    const encryptedText = this.CryptoJS.AES.encrypt(
        customerName + "|" + this.formModel.house_no+ "|" + this.formModel.prefix_address,
        "k23qa"
      ).toString();

            this.formModel.uid = encryptedText;

      console.log("encryptedText", encryptedText);
      // this.formModel.active = true;
      // event.preventDefault();

      db.collection(this.documentPath())
        .doc()
        .set(this.formModel)
        .then(() => {
          this.formModel = {};
          this.getTotalRow();

          this.$vs.notify({
            color: "danger",
            title: "",
            text: "บันทึกข้อมูลสำเร็จแล้ว",
          });
        })
        .catch((error) => {
          console.log(error);
        });
      this.page = 1;
    },
    close() {
      this.formModel = {};
    },

    getPrefixItem(item) {
      for (let index = 0; index < this.prefix_addres_list.length; index++) {
        const element = this.prefix_addres_list[index];

        console.log("element.value=" + element.value);
        console.log("item=" + item);

        if (element.value == item) {
          return element;
        }
      }
      return null;
    },

    openUpdatePromt(item) {
      console.log("[[openUpdatePromt]]", item);

      this.formModel.house_no = item.house_no;

      this.formModel.name = item.name;
      this.formModel.email = item.email;
      this.formModel.phone = item.phone;

      this.oldUpdateValue.house_no = item.house_no;
      this.oldUpdateValue.prefix_address = item.prefix_address;

      this.formModel.prefix_address = this.getPrefixItem(item.prefix_address);

      console.log("item.active =>", item.active);

      if (typeof item.active === "undefined") {
        this.formModel.active = true; // active === undefined
        console.log("true => ", true);
      } else {
        this.formModel.active = item.active;
        console.log("active =>", item.active);
      }
      this.selectedId = item.key;
      this.updatePrompt = true;
    },

    isEmpty(checkValue) {
      if (checkValue == undefined || checkValue == null || checkValue == "") {
        return true;
      }

      return false;
    },

    async checkExistAddress() {
      console.log("checkExistAddress formModel=", this.formModel);

      var customerRef = await db
        .collection(this.documentPath())
        .where("house_no", "==", parseInt(this.formModel.house_no))
        .where("prefix_address", "==", "")
        .get();

      if (!this.isEmpty(this.formModel.prefix_address)) {
        var prefix_address = this.formModel.prefix_address.value + "";
        console.log("prefix_address=" + prefix_address);

        customerRef = await db
          .collection(this.documentPath())
          .where("house_no", "==", parseInt(this.formModel.house_no))
          .where("prefix_address", "==", prefix_address)
          .get();
      }

      // const customerRef = ref.get();
      console.log("house.docs.length=" + customerRef.docs.length);

      if (customerRef.docs.length < 1) {
        console.error("No such house document! ");
        return false;
      }
      return true;
    },

    isUpdateOldValue() {
      if (
        this.oldUpdateValue.house_no == this.formModel.house_no &&
        this.oldUpdateValue.prefix_address == ( this.isEmpty(this.formModel.prefix_address) ? ""
        : this.formModel.prefix_address.value)
      ) {
        return true;
      }
      return false;
    },

    async updateHome(event) {
      console.log("updateHome this.formModel=", this.formModel);

      console.log("updateHome this.isUpdateOldValue()=", this.isUpdateOldValue());

      if (!this.isUpdateOldValue() && (await this.checkExistAddress())) {
        this.$vs.notify({
          color: "danger",
          title: "",
          text: "เพิ่มข้อมูลไม่สำเร็จ บ้านเลขที่นี้มีอยู่ในระบบแล้ว",
        });
        return;
      }

      const customerName = $cookies.get("customer");

      this.formModel.email = this.formModel.email ? this.formModel.email : "";
      this.formModel.phone = this.formModel.phone ? this.formModel.phone : "";
      this.formModel.name = this.formModel.name ? this.formModel.name : "";
      this.formModel.house_no = parseInt(this.formModel.house_no);
      // this.formModel.prefix_address = this.formModel.name ? this.formModel.name : "";

      this.formModel.prefix_address =
        this.formModel.prefix_address == null ||
        this.formModel.prefix_address == undefined ||
        this.formModel.prefix_address == ""
          ? ""
          : this.formModel.prefix_address.value;

        const encryptedText = this.CryptoJS.AES.encrypt(
        customerName + "|" + this.formModel.house_no+ "|" + this.formModel.prefix_address,
        "k23qa"
      ).toString();

            // this.formModel.uid = encryptedText;

      // const encryptedText = this.CryptoJS.AES.encrypt(
      //   customerName + "|" + this.formModel.house_no,
      //   "k23qa"
      // ).toString();

      console.log("encryptedText", encryptedText);

      this.formModel.uid = encryptedText;

      // event.preventDefault();
      db.collection(this.documentPath())
        .doc(this.selectedId)
        .update(this.formModel)
        .then(() => {
          console.log("updateHome successfully updated!");
          // this.$router.push("/list");
          this.getTotalRow();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deleteHouse() {
      const customerName = $cookies.get("customer");
      db.collection(this.documentPath())
        .doc(this.selectedId)
        .delete()
        .then(() => {
          console.log("Document deleted!");
          this.getTotalRow();
        })
        .catch((error) => {
          console.error(error);
        });
    },

    async genCustomerCode() {
      console.log("this.allCustomerCode=", this.allCustomerCode);

      // console.log("checkdoc=",checkdoc.empty);
      //let count = 0;
      let { docs } = await db.collection("customer").get();

      let allCust = docs.map((doc) => {
        console.log("doc=", doc.id);

        // const { id } = doc
        const data = doc.data();
        data.id = doc.id;
        return data;
      });

      console.log("this.allCust=", allCust);

      let count = 0;
      for (var i = 0; i < allCust.length; i++) {
        let d = allCust[i];
        const customer_code = d.customer_code;

        if (customer_code !== null) {
          console.log("customer_code=" + customer_code);
        } else {
          count++;

          let update_cust_code = "";

          let checkdoc = await db
            .collection("customer")
            .where("customer_code", "==", count + "")
            .get();
          if (!checkdoc.empty) {
            update_cust_code = "0" + count;
          } else {
            update_cust_code = "" + count;
          }

          console.log(
            "update with update_cust_code=" +
              update_cust_code +
              " custid=" +
              d.id
          );

          db.collection("customer")
            .doc(d.id)
            .update({ customer_code: update_cust_code })
            .then(() => {
              console.log("update customer_code successfully updated!");
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },

    openAddhome() {
      this.formModel = {};
      this.formModel.active = true;
      this.activePromptAddHome = true;
    },

    openDeletePromt(id) {
      this.selectedId = id;
      this.deletePrompt = true;
    },

    getAddressName(data) {
      return data.prefix_address == null || data.prefix_address == ""
        ? data.house_no
        : data.prefix_address + "/" + data.house_no;
    },

    getNotifyAddressName(data) {
      return this.isEmpty(this.formModel.prefix_address)
        ? this.formModel.house_no
        : this.formModel.prefix_address.value + "/" + this.formModel.house_no;
    },

    async getCustomerPrefixAddress() {
      const customerName = $cookies.get("customer");

      await db
        .collection("customer/" + customerName + "/prefix_address")
        .onSnapshot((snapshotChange) => {
          this.prefix_addres_list = [];
          snapshotChange.forEach((doc) => {
            console.log("doc.id=" + doc.id);
            this.prefix_addres_list.push({
              title: doc.id + "/",
              value: doc.id,
            });
          });
        });
    },

    async getTotalRow() {
      const customerName = $cookies.get("customer");
      let totalCustomer = await db
        .collection(this.documentPath())
        .get()
        .then((document) => document.docs.length);
      this.total = Math.ceil(totalCustomer / this.limit);
      this.totalItems = totalCustomer;
    },

    async getLastItem() {
      console.log("[[getLastItem]]");

      var _limit = (this.page - 1) * this.show_per_page;
      console.log("this.page=" + this.page);
      console.log("this.show_per_page=" + this.show_per_page);
      console.log("_limit=" + _limit);

      if (_limit == 0) return null;

      var reference = db.collection(this.documentPath());

      reference = reference.orderBy("house_no", "asc");
      reference = reference.limit(_limit);

      const snapshot = await reference.get();
      console.log("snapshot.docs.length=" + snapshot.docs.length);

      const last = snapshot.docs[snapshot.docs.length - 1];
      return last;
    },

    async getTotalRow() {
      let totalCustomer = await this.getDbWithCondition(true, null)
        .get()
        .then((document) => document.docs.length);

      console.log("totalCustomer=" + totalCustomer);

      this.total = Math.ceil(totalCustomer / this.show_per_page);
      this.totalItems = totalCustomer;
    },

    getDbWithCondition(isForCountAll = false, lastItem) {
      console.log("[[getDbWithCondition]]");
      var reference = db.collection(this.documentPath());

      if (isForCountAll == true) {
        return reference;
      }
      reference = reference.orderBy("house_no", "asc");

      console.log("this.show_per_page=" + this.show_per_page);

      if (lastItem != null) {
        reference = reference.startAfter(lastItem).limit(this.show_per_page);
      } else {
        reference = reference.limit(this.show_per_page);
      }

      return reference;
    },

    async listHome({ page = 1 }) {
      console.log("page=" + page);

      this.isLoad = true;

      this.getTotalRow();

      this.getLastItem().then((lastItem) => {
        console.log("lastItem", lastItem);

        this.getDbWithCondition(false, lastItem).onSnapshot(
          (snapshotChange) => {
            this.items = [];

            snapshotChange.forEach((doc) => {

              this.items.push({
                key: doc.id,
                prefix_address: doc.data().prefix_address,
                house_no_display: this.getAddressName(doc.data()),
                house_no: doc.data().house_no,
                name: doc.data().name,
                email: doc.data().email,
                phone: doc.data().phone,
                uid: doc.data().uid,
                active: doc.data().active,
              });

              this.isLoad = false;
            });
          }
        );
      });
      // console.log("last=",last);
    },

    previousData: function () {
      this.listHome({ page: this.page });
    },
    nextData: function () {
      this.listHome({ page: this.page });
    },
    inputData: function (value) {
      this.page = value;
      this.listHome({ page: this.page });
    },

    async listHome_x() {
      const customerName = $cookies.get("customer");

      this.getTotalRow();

      // console.log("listHome : totalCustomer = "+totalCustomer+" limit = "+this.limit +" total = "+this.total)
      // console.log("listHome : totalItems = ",this.totalItems)

      let indexOf = 0;
      let get = db.collection(this.documentPath()).orderBy("house_no", "asc");
      get
        .get()
        .then((document) => {
          let last = document.docs[indexOf];
          db.collection(this.documentPath())
            .orderBy("house_no", "asc")
            .startAt(last)
            .limit(this.limit)
            .onSnapshot((snapshotChange) => {
              this.items = [];
              snapshotChange.forEach((doc) => {
                //  console.log("list doc.data()", doc.data());
                this.items.push({
                  key: doc.id,
                  prefix_address: doc.data().prefix_address,
                  house_no_display: this.getAddressName(doc.data()),
                  house_no: doc.data().house_no,
                  name: doc.data().name,
                  email: doc.data().email,
                  phone: doc.data().phone,
                  uid: doc.data().uid,
                  active: doc.data().active,
                });
                this.isLoad = false;
              });
            });
        })
        .catch((err) => {
          console.log("err", err);
        });
    },

    previousData_x: function () {
      this.fetchHome({ page: this.page - 1, limit: 10 });
    },
    nextData_x: function () {
      this.fetchHome({ page: this.page - 1, limit: 10 });
    },
    inputData_x: function (value) {
      this.page = value;
      this.fetchHome({ page: this.page - 1, limit: 10 });
    },

    fetchHome_x: async function ({ page = 0, limit = 10 }) {
      this.isLoad = true;
      let indexOf = limit * page;
      console.log(
        "fetchHome : indexOf [" + indexOf + "] =" + this.total + " * " + page
      );
      const customerName = $cookies.get("customer");
      let get = db.collection(thi.documentPath()).orderBy("house_no", "asc");
      get
        .get()
        .then((document) => {
          let last = document.docs[indexOf];
          db.collection(this.documentPath())
            .orderBy("house_no", "asc")
            .startAt(last)
            .limit(limit)
            .onSnapshot((snapshotChange) => {
              this.items = [];
              snapshotChange.forEach((doc) => {
                //  console.log("list doc.data()", doc.data());
                this.items.push({
                  key: doc.id,
                  prefix_address: doc.data().prefix_address,
                  house_no_display: this.getAddressName(doc.data()),
                  house_no: doc.data().house_no,
                  name: doc.data().name,
                  email: doc.data().email,
                  phone: doc.data().phone,
                  uid: doc.data().uid,
                  active: doc.data().active,
                });
                this.isLoad = false;
              });
            });
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
  },
  components: {
    Datepicker,
  },
  created() {
    this.roleInfo = $cookies.get("roleInfo");
    this.userInfo = $cookies.get("userInfo");
    console.log("[[this.userInfo]]", this.userInfo);

    if ($cookies.get("userInfo") === null) {
      this.$router.push("/pages/login").catch((error) => {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      });
    }

    this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", false);
    const parameters = this.$route.query;

    const home = $cookies.get("home");
    console.log("home", home);

    this.houseInfo = $cookies.get("houseinfo");
    console.log("this.houseInfo=", this.houseInfo);

    this.listHome({ page: this.page });
    this.getCustomerPrefixAddress();
  },
};
</script>

<style>
.con-exemple-prompt {
  padding: 10px;
  padding-bottom: 0px;
}
.vs-input {
  width: 100%;
  margin-top: 10px;
}
.page-item {
}
</style>
